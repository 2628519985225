import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EmailService } from '../services/email.service';
@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  sender: string;
  emailSender: string;
  message: string;
  constructor(private titleService: Title,
              private emailService: EmailService) { }

  ngOnInit() {
    this.setTitle("Alibaba");
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public senMail() {

    console.log(this.message);
    if(this.emailSender!="" && this.message!=""){
      let messageText ="Demandes envoyer par :"+ this.sender+ " Email: "+this.emailSender+" Contenu: "+this.message;
      this.emailService.sendMail("m.andriamialy@gmail.com", this.message);
      this.emailSender="", this.message=""
    }else{
      alert("l'adresse email et le message ne doit pas etre vide!")
    }
    
  }
}

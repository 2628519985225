import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  data: any;
  constructor(private apollo: Apollo,
    private toastr: ToastrService) {
  }

  /**
   * 
   * @param to destainataire du message
   * @param message texte du message
   */
  sendMail(to, message){
    this.apollo.query({
      query: gql`query {
        mail(
          to: "${to}",
          message: "${message}")
        {
          to,
        message
        }
      }`
    }).subscribe(({ data, loading }) => {
      this.data = data;

      this.toastr.success('Email envoyé avec succès', 'votre message à été envoyer à Alibaba.mg', {
        timeOut: 5000,
      });
      console.log(data);
    });
  }

  listeNouveau(){
    this.apollo.query({
      query: gql`query {
        listes(status: nouveau)
          
        {
          id
          nom
          cin
          telephone1
        }
      }`
    }).subscribe(({ data, loading }) => {
      this.data = data;
      console.log(data);
    });
  }


}
